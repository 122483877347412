import { Passwordless } from 'amazon-cognito-passwordless-auth';
import {
  PasswordlessContextProvider,
  Passwordless as PasswordlessComponent,
  Fido2Toast,
} from 'amazon-cognito-passwordless-auth/react';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { LDProvider } from 'launchdarkly-react-client-sdk';

import App from '@/app';

import './index.css';

import 'amazon-cognito-passwordless-auth/passwordless.css';
import '@cloudscape-design/global-styles/index.css';
import logo from '@/assets/forgestop-icon.svg';
import { LD_CLIENT_KEY } from './constants/env';

console.debug('App built at:', import.meta.env.VITE_APP_BUILD_DATE);

Passwordless.configure({
  cognitoIdpEndpoint: import.meta.env.VITE_COGNITO_IDP_ENDPOINT,
  clientId: import.meta.env.VITE_CLIENT_ID,
  fido2: {
    baseUrl: import.meta.env.VITE_FIDO2_BASE_URL,
    authenticatorSelection: {
      userVerification: 'required',
    },
  },
  debug: console.debug,
});

const el = document.getElementById('root');
if (el) {
  const root = createRoot(el);
  root.render(
    <PasswordlessContextProvider enableLocalUserCache>
      <PasswordlessComponent
        brand={{
          backgroundImageUrl: '/img/bg-signin-full.jpg',
          // customerName: 'Forgestop',
          customerLogoUrl: logo,
        }}
      >
        <React.StrictMode>
          <LDProvider clientSideID={LD_CLIENT_KEY}>
            <App />
          </LDProvider>
        </React.StrictMode>
      </PasswordlessComponent>
      <Fido2Toast />
    </PasswordlessContextProvider>,
  );
} else {
  throw new Error('Could not find root element');
}
