import { cn } from "@/lib/utils"
import { Package, Radio, Truck, Plane, Nfc, Factory } from "lucide-react"
import { useState } from "react"
import { ScrollArea } from "./ui/scroll-area";
import { useScanQuery } from "@/hooks/use-scan-query";
import { title } from "process";
import { wineTid } from "@/modules/scans/tid";

interface JourneyStepsProps { 
  tidId?: string; 
  steps?: { 
    name: string; 
    coordinates: [number, number] 
  }[]
}



const winerySteps: {title: string; description: string; type: ScanType}[] = [
  { 
    title: "Production Facility", 
    description: "Franschhoek, South Africa", 
    type: "factory" 
  },
  { 
    title: "Distribution Center", 
    description: "Regensdorf, Switzerland", 
    type: "truck" 
  },
  { 
    title: "Reseller", 
    description: "Marseille, France", 
    type: "package" 
  },
];

type Step = {title: string; description: string; type: ScanType}

const defaultSteps: Step[] = [
  { 
    title: "Production Facility", 
    description: "50 Pointe Dr, Brea California 92821", 
    type: "factory" 
  },
  {
    title: "Shipped",
    description: "Via DBS3869",
    type: "truck"
  },
  {
    title: "Distribution Center",
    description: "260 Peachtree Street, Atlanta, Georgia",
    type: "signal"
  },
  {
    title: "Shipped",
    description: "Via UPS 3 Day Select",
    type: "truck"
  },
  {
    title: "Departed Facility",
    description: "New York",
    type: "plane"
  },
  {
    title: "Customer Received",
    description: "Expo Porte de Versailles, Paris",
    type: "package"
  }
] as const;

export function JourneySteps({ tidId, steps }: JourneyStepsProps) {
  const scanQuery = useScanQuery({
    tidId,
    pagination: {
      take: 100,
      skip: 0,
    },
  });

  const scanSteps: Step[] = scanQuery.data?.scans
    .filter(scan => scan.status === 'authenticated' && scan.lat && scan.lon)
    .map( scan => ({
      id: scan._id,
      title: "Consumer Authentication",
      description: `${scan.city} - ${scan.countryName}`,
      type: "nfc",
    })) ?? [];

    const initialSteps = wineTid === tidId ? winerySteps : defaultSteps;

  const allSteps = [...initialSteps, ...scanSteps];
  return (
    <div className="p-6 flex flex-col gap-6">
      <div className="flex items-center justify-between flex-wrap gap-4">
        <h3 className="font-semibold">Product journey</h3>
        <p className="text-xs text-muted-foreground">DUE / EMA425 V12</p>
        <p className="text-muted-foreground">{tidId}</p>
      </div>
      <div className="flex flex-col gap-0">
        <ScrollArea className="h-[540px]">
          {allSteps?.map((step, index) => (
            <JourneyStep
              title={step.title}
              description={step.description}
              type={step.type}
              isCompleted={index === allSteps.length - 1}
            />
          ))}
        </ScrollArea>
      </div>
    </div>
  )
}

type ScanType = 'signal' | 'truck' | 'package' | 'plane' | 'nfc' | 'factory';

function JourneyStep({ 
  title, 
  tidId,
  description, 
  subDescription,
  isCompleted,
  isActive,
  type = 'signal'
}: { 
  title: string
  tidId?: string
  description: string
  subDescription?: string
  isCompleted?: boolean
  isActive?: boolean
  type?: ScanType
}) {
  const [isExpanded, setIsExpanded] = useState(true);
  
  return (
    <div className={cn(
      "relative flex gap-4 pb-4",
      subDescription && "bg-muted/50 -mx-6 px-6 pt-4 rounded-lg"
    )}>
      <div className={cn(
        "absolute left-5 top-[26px] bottom-0 w-[2px] bg-border",
        !subDescription && "opacity-40",
        isActive && "bottom-4",
        isCompleted && "hidden"
      )} />
      <div className="h-10 w-10 rounded-full bg-blue-100 flex items-center justify-center flex-shrink-0 relative z-10">
        {type === 'signal' && <Radio className="h-5 w-5 text-blue-600" />}
        {type === 'truck' && <Truck className="h-5 w-5 text-blue-600" />}
        {type === 'package' && <Package className="h-5 w-5 text-blue-600" />}
        {type === 'plane' && <Plane className="h-5 w-5 text-blue-600" />}
        {type === 'nfc' && <Nfc className="h-5 w-5 text-blue-600" />}
        {type === 'factory' && <Factory className="h-5 w-5 text-blue-600" />}
      </div>
      <div className="flex-1 pt-1">
        <div className="flex items-start justify-between">
          <div className="space-y-1">
            <h4 className="text-base font-medium leading-none">{title}</h4>
            <p className="self-stretch text-zinc-950 text-sm font-medium font-['Inter'] leading-tight">{tidId}</p>
            <p className="text-sm text-muted-foreground">{description}</p>
            {subDescription && (
              <p className="text-sm text-muted-foreground">{subDescription}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}