import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { CreateProductFormData } from '@/components/forms/product-form';
import { useToast } from '@/components/ui/use-toast';
import { apiPaths } from '@/constants/api-paths';
import { api } from '@/lib/api';
import { createApiResponseSchema } from '@/schemas/api-response-schema';
import type { Product } from '@/schemas/product';
import { ProductSchema } from '@/schemas/product';

import { useAppSelector } from './use-app-selector';

const ProductApiResponseSchema = createApiResponseSchema(ProductSchema);

async function updateProduct({ brandId, ...product }: CreateProductFormData) {
  const { data } = await api.put<Product>(
    apiPaths.PRODUCT_BY_ID({
      brandId: brandId ?? '',
      productId: product.productId ?? '',
    }),
    {
      ...product,
    },
  );

  return ProductApiResponseSchema.parse(data);
}

export function useUpdateProductMutation() {
  const { toast } = useToast();
  const selectedBrand = useAppSelector((state) => state.brands.selected);
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (product: CreateProductFormData) =>
      updateProduct({ ...product, brandId: selectedBrand?.id ?? '' }),
    mutationKey: ['update-product'],
    onSuccess: ({ data }) => {
      toast({
        title: 'Product updated',
      });
      // ✅ Invalidate both the product list & specific product query
      queryClient.invalidateQueries({
        queryKey: ['products', selectedBrand?.id], // Refresh the product list
      });

      void queryClient.invalidateQueries({
        queryKey: ['product', data.id], // Refresh the single product details
      });
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['products', selectedBrand?.id],
      });
    },
  });
}
