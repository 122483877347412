import { createBrowserRouter, Navigate } from 'react-router-dom';

import { ShortenedUUID } from '@/components/shortened-uuid';
import { routes } from '@/constants/routes';
import { Metafields } from '@/modules/metafields';
import { Orders } from '@/modules/orders';
import { OrdersLayout } from '@/modules/orders/orders.layout';
import { Batch } from '@/modules/products/batch';
import { ProductDetails } from '@/modules/products/product-details';
import { Products } from '@/modules/products/products';
import { Scans } from '@/modules/scans';
import { ScansLayout } from '@/modules/scans/scans-layout';
import { Tid } from '@/modules/scans/tid';
import { Support } from '@/modules/support';
import { Analytics } from '@/pages/dashboard/analytics';
import { AnalyticsLayout } from '@/pages/dashboard/analytics/analytics-layout';
import { DashboardLayout } from '@/pages/dashboard/dashboard.layout';
import { SettingsProfile } from '@/pages/dashboard/settings';
import { SettingsAccount } from '@/pages/dashboard/settings/account';
import { SettingsAppearance } from '@/pages/dashboard/settings/appearance';
import { SettingsBrand } from '@/pages/dashboard/settings/brand';
import { SettingsDisplay } from '@/pages/dashboard/settings/display';
import { SettingsNotifications } from '@/pages/dashboard/settings/notifications';
import { SettingsLayout } from '@/pages/dashboard/settings/settings-layout';
// import { Tags } from '@/pages/dashboard/tags';

import { ProtectedRoute } from './protected-route';
import { Root } from './root';

import type { Params } from 'react-router-dom';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
  },
  {
    path: 'admin',
    element: (
      <ProtectedRoute>
        <DashboardLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        index: true,
        element: <Navigate to="dashboard" />,
      },
      {
        path: 'dashboard',
        element: <AnalyticsLayout />,
        children: [
          {
            element: <Analytics />,
            index: true,
          },
        ],
      },
      {
        path: 'scans',
        element: <ScansLayout />,
        children: [
          {
            element: <Scans />,
            index: true,
          },
          {
            path: ':tidId',
            element: <Tid />,
          },
        ],
      },
      {
        path: '/admin/products',
        element: <Products />,
      },
      {
        path: '/admin/products/:productId',
        element: <ProductDetails />,
      },
      {
        path: '/admin/products/:productId/batches/:batchId',
        element: <Batch />,
        loader: ({ params }) => params,
        handle: {
          crumb: (data: Params) => (
            <ShortenedUUID copyHidden uuid={data.batchId!} />
          ),
        },
      },
      {
        path: routes.ORDERS,
        element: <OrdersLayout />,
        children: [
          {
            element: <Orders />,
            index: true,
          },
        ],
      },
      {
        path: 'metafields',
        element: <Metafields />,
      },
      {
        path: 'support',
        element: <Support />,
      },
      {
        path: 'settings',
        element: <SettingsLayout />,
        children: [
          {
            index: true,
            element: <Navigate to="profile" />,
          },
          {
            path: 'profile',
            element: <SettingsProfile />,
          },
          {
            path: 'brand',
            element: <SettingsBrand />,
          },
          {
            path: 'account',
            element: <SettingsAccount />,
          },
          {
            path: 'appearance',
            element: <SettingsAppearance />,
          },
          {
            path: 'notifications',
            element: <SettingsNotifications />,
          },
          {
            path: 'display',
            element: <SettingsDisplay />,
          },
        ],
      },
    ],
  },
]);
