import { Save, X } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { ShortenedUUID } from '@/components/shortened-uuid';
import type { TagsField } from '@/components/tags';
import { Tags } from '@/components/tags';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form';

import { getMetafields } from '@/helpers/get-metafields';
import { useAppSelector } from '@/hooks/use-app-selector';
import { useBatchQuery } from '@/hooks/use-batch-query';
import { useProductQuery } from '@/hooks/use-product-query';
import { useSearchFilters } from '@/hooks/use-search-filters';
import { useTidsNestedQuery } from '@/hooks/use-tids-query';
import type { UpdateBatchFormData } from '@/hooks/use-update-batch-mutation';
import { useUpdateBatchMutation } from '@/hooks/use-update-batch-mutation';

import { tidColumns } from './components/data-table/tid-columns';
import { DataTable } from './components/data-table/tid-table';

import type {
  ColumnFiltersState,
  PaginationState,
} from '@tanstack/react-table';
import { CarbonFootprint } from '@/components/charts/carbon-footprint';
import { EsgCard } from '@/components/charts/esg';
import { Separator } from '@/components/ui/separator';
import { cn } from '@/lib/utils';

export function Batch() {
  const { digitalProductPassport } = useFlags();
  const { batchId, productId } = useParams() as {
    batchId: string;
    productId: string;
  };

  const brand = useAppSelector((state) => state.brands.selected);
  const productQuery = useProductQuery({ productId, brandId: brand?.id ?? '' });
  const filters = useSearchFilters();
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const batchQuery = useBatchQuery({ batchId, productId });

  const tidsQuery = useTidsNestedQuery({ batchId, productId });
  const updateBatchMutation = useUpdateBatchMutation();

  useEffect(() => {
    setColumnFilters(filters);
  }, [filters]);

  const metafields = getMetafields(batchQuery.data?.batch.batchMetafields);

  const [tagInput, setTagInput] = useState('');
  const form = useForm<TagsField>({
    values: {
      tags: [...(batchQuery.data?.batch.tags ?? [])],
    },
    mode: 'onChange',
  });

  useEffect(() => {
    if (batchQuery.data) {
      const tags = batchQuery.data.batch.tags ?? [];
      form.setValue('tags', tags);
      setTagInput('');
    }
  }, [batchQuery.data, form]);

  const handleTagInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setTagInput(value);

    if (value.endsWith(',')) {
      const newTag = value.slice(0, -1).trim();
      const currentTags = form.getValues('tags') || [];

      if (newTag && !currentTags.includes(newTag)) {
        form.setValue('tags', [...currentTags, newTag]);
      }
      setTagInput('');
    }
  };

  const removeTag = (tagToRemove: string) => {
    const updatedTags = (form.getValues('tags') || []).filter(
      (tag) => tag !== tagToRemove,
    );
    form.setValue('tags', updatedTags);
  };

  const resetHandler = () => {
    void batchQuery.refetch();
    form.resetField('tags');
    setTagInput('');
  };

  const submitHandler = (data: UpdateBatchFormData) => {
    updateBatchMutation.mutate({
      brandId: brand?.id ?? '',
      batchId,
      productId,
      ...data,
    });
  };

  const onSubmit = (data: UpdateBatchFormData) => {
    if (tagInput.trim()) {
      const currentTags = form.getValues('tags') || [];
      const newTag = tagInput.trim();

      if (!currentTags.includes(newTag)) {
        form.setValue('tags', [...currentTags, newTag]);
      }
    }

    console.log('Form submitted with data:', data);
  };

  const handleBlur = () => {
    if (tagInput.trim()) {
      const currentTags = form.getValues('tags') || [];
      const newTag = tagInput.trim();

      if (!currentTags.includes(newTag)) {
        form.setValue('tags', [...currentTags, newTag]);
      }
      setTagInput('');
    }
  };

  return (
    <div className="flex-1 space-y-4 p-8 pt-6">
      <div className="flex items-center justify-between space-y-2">
        <div className="flex gap-1">
          <Link className="text-primary hover:underline" to="../products">
            <h2 className="text-2xl font-medium tracking-tight">Products</h2>
          </Link>
          <h2 className="text-2xl font-medium tracking-tight">/</h2>
          <h2 className="text-2xl font-medium tracking-tight">
            {productQuery.data?.product.sku}
          </h2>
          <h2 className="text-2xl font-medium tracking-tight">/</h2>
          <Link
            className="text-primary hover:underline"
            to={`../products/${productId}`}
          >
            <h2 className="text-2xl font-medium tracking-tight">Batches</h2>
          </Link>
          <h2 className="text-2xl font-medium tracking-tight">/</h2>
          <h2 className="text-2xl font-bold tracking-tight">
            {batchQuery.data?.batch.lot}
          </h2>
        </div>
      </div>
      {/* <Card>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Created At</TableHead>
              <TableHead>Product Name</TableHead>
              <TableHead>SKU</TableHead>
              <TableHead className="text-right">Count (Tags)</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableCell className="font-medium">
                {batchQuery.data?.batch.createdAt}
              </TableCell>
              <TableCell>{batchQuery.data?.batch.product?.name}</TableCell>
              <TableCell>{batchQuery.data?.batch.product?.sku}</TableCell>
              <TableCell className="text-right">
                {batchQuery.data?.batch.tidCount}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card> */}
      <div className="grid gap-4 lg:grid-cols-6">
        <Card className="col-span-2 flex flex-col gap-4">
          <CardHeader className="flex flex-col items-center justify-between space-y-0 pb-6 bg-muted gap-4">
            <CardTitle className="text-base font-medium w-full flex gap-4 justify-between items-center">
              <div className="grow shrink basis-0 justify-start items-start gap-4 inline-flex">
                {/* 
                <div className='flex justify-between w-full'>
                  
                  <div className="flex items-center gap-1 text-muted-foreground">
                    <span className="text-muted-foreground">Total Scans</span>
                    <NfcIcon /> 
                    {scanQuery.data?.pagination?.total}
                  </div> 
                </div>
                  */}
                <div className="bg-white rounded-lg shadow-sm">
                  <img
                    src={batchQuery.data?.batch.product?.imageUrl ?? ''}
                    alt={batchQuery.data?.batch.product?.name}
                    className="w-20 h-20 object-cover rounded-lg"
                  />
                </div>
                <div className="grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
                  <div className="self-stretch text-zinc-950 text-lg font-semibold capitalize leading-7 flex items-center justify-between">
                    {batchQuery.data?.batch.product?.name}
                  </div>
                  <div className="self-stretch justify-start items-start gap-1 inline-flex">
                    <div className="text-zinc-500 text-sm font-normal leading-tight">
                      Created at:
                    </div>
                    <div className="text-zinc-500 text-sm font-normal leading-tight">
                      {batchQuery.data?.batch.createdAt}
                    </div>
                  </div>
                  <div className="self-stretch flex justify-between">
                    <div className="self-stretch justify-start items-start gap-1 inline-flex">
                      <div className="text-zinc-500 text-sm font-normal leading-tight">
                        SKU:
                      </div>
                      <div className="text-zinc-500 text-sm font-normal leading-tight">
                        {batchQuery.data?.batch.product?.sku}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardTitle>
          </CardHeader>

          <CardContent className="w-full flex-1 flex flex-col gap-4">
            <div className="text-base font-medium w-full flex gap-4 justify-between items-center">
              <span className="text-muted-foreground">Batch Id:</span>
              <ShortenedUUID
                copyHidden
                uuid={batchQuery.data?.batch.id ?? ''}
              />
            </div>
            <p className="w-full flex gap-4 justify-between">
              <span className="text-muted-foreground">Lot:</span>
              {batchQuery.data?.batch.lot ?? ''}
            </p>
            {batchQuery.data?.batch.producedAt ? (
              <p className="w-full flex gap-4 justify-between">
                <span className="text-muted-foreground">Produced at:</span>
                {batchQuery.data.batch.producedAt}
              </p>
            ) : null}
            {batchQuery.data?.batch.expiresAt ? (
              <p className="w-full flex gap-4 justify-between">
                <span className="text-muted-foreground">Expires at:</span>
                {batchQuery.data.batch.expiresAt}
              </p>
            ) : null}
            {metafields.map((field) =>
              field.value ? (
                <p
                  className="w-full flex gap-4 justify-between"
                  key={field.key}
                >
                  <span className="text-muted-foreground">{field.key}:</span>
                  {field.value}
                </p>
              ) : null,
            )}
          </CardContent>
          <Separator />
          <CardFooter className="flex-col gap-4">
            <p className="w-full flex gap-4 justify-between">
              <span className="text-muted-foreground">Tags:</span>
            </p>
            <Form {...form}>
              <form
                className="flex flex-col gap-2 w-full"
                onReset={resetHandler}
                onSubmit={form.handleSubmit(submitHandler)}
              >
                <FormField
                  control={form.control}
                  name="tags"
                  render={({ field }) => (
                    <FormItem className="flex-1">
                      <FormControl>
                        <Tags
                          field={field}
                          onBlur={handleBlur}
                          onChange={handleTagInput}
                          onRemove={removeTag}
                          tagInput={tagInput}
                          tags={field.value}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className="flex gap-2 self-end">
                  <Button
                    className="flex gap-2"
                    title="Save Changes"
                    type="submit"
                  >
                    <Save className="h-4 w-4" />
                    Save
                  </Button>
                  <Button
                    className="flex gap-2"
                    title="Revert Changes"
                    type="reset"
                    variant="secondary"
                  >
                    <X className="h-4 w-4" />
                    Revert
                  </Button>
                </div>
              </form>
            </Form>
          </CardFooter>
        </Card>
        {digitalProductPassport && (
          <>
            <CarbonFootprint className="col-span-2" />
            <EsgCard className="col-span-2" />
          </>
        )}
        <div
          className={cn(digitalProductPassport ? `col-span-6` : `col-span-4`)}
        >
          <DataTable
            columnFilters={columnFilters}
            columns={tidColumns}
            data={tidsQuery.data?.tags ?? []}
            onColumnFiltersChange={setColumnFilters}
            onPaginationChange={setPagination}
            pagination={pagination}
            rowCount={tidsQuery.data?.pagination?.total}
            showToolbar={false}
          />
        </div>
      </div>
    </div>
  );
}
