import { ChevronLeft, Pen } from 'lucide-react';
import { useState } from 'react';

import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Separator } from '@/components/ui/separator';
import { Skeleton } from '@/components/ui/skeleton';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '@/hooks/use-app-selector';
import { useProductQuery } from '@/hooks/use-product-query';
import { useForm } from 'react-hook-form';
import { useUpdateProductMutation } from '@/hooks/use-update-product-mutation';
import { Authorization } from '@/lib/authorization';

type TProductFormData = {
  name: string;
  sku: string;
};

export function ProductInformation() {
  const navigate = useNavigate();

  const [isEditing, setIsEditing] = useState(false);
  const { productId } = useParams() as { productId: string };
  const brand = useAppSelector((state) => state.brands.selected);
  const productQuery = useProductQuery({ productId, brandId: brand?.id ?? '' });
  const updateProductMutation = useUpdateProductMutation();

  const { register, handleSubmit, reset } = useForm<TProductFormData>({
    defaultValues: {
      name: productQuery.data?.product.name || '',
      sku: productQuery.data?.product.sku || '',
    },
    values: {
      name: productQuery.data?.product.name || '',
      sku: productQuery.data?.product.sku || '',
    },
  });

  const onUpdateProductDetails = async (data: TProductFormData) => {
    await updateProductMutation.mutateAsync({
      productId: productId,
      name: data.name,
      sku: data.sku,
    });
    setIsEditing(false);
  };

  return (
    <div className="py-4 px-8 space-y-6">
      <div className="flex items-center justify-between space-y-2 mb-4">
        <div className="flex items-center gap-1">
          <Button
            className="hidden lg:flex h-6 w-6 p-0 rounded-lg"
            size="sm"
            variant="outline"
            onClick={() => navigate(-1)}
            type="button"
          >
            <ChevronLeft className="h-4 w-4" />
          </Button>
          <h2 className="mx-4 text-2xl font-bold tracking-tight">
            {productQuery.data?.product.name}
          </h2>
          <Badge variant="outline">Active</Badge>
        </div>
      </div>

      <form onSubmit={handleSubmit(onUpdateProductDetails)}>
        <Card className="flex flex-row items-start p-4 space-x-3 rounded-xl">
          <div className="w-36 h-40 bg-gray-200 rounded-md overflow-hidden">
            {productQuery.data?.product.imageUrl ? (
              <div className="w-full h-full flex items-center justify-center">
                <img
                  src={productQuery.data?.product.imageUrl ?? ''}
                  alt={productQuery.data?.product.name}
                  className="object-cover rounded-lg"
                />
              </div>
            ) : (
              <Skeleton className="w-full h-full object-cover" />
            )}
          </div>

          {/* Product Details */}
          <CardContent className="flex flex-col flex-1 py-0">
            <div className="w-full">
              {isEditing ? (
                <div className="h-10 w-full flex justify-end space-x-3 items-center">
                  <Button
                    className="rounded-lg"
                    onClick={() => {
                      reset();
                      setIsEditing(false);
                    }}
                    variant="outline"
                    type="button"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="bg-black text-white hover:text-slate-400 hover:bg-gray-800 rounded-lg"
                    type="submit"
                  >
                    Save
                  </Button>
                </div>
              ) : (
                <div className="h-10 w-full flex justify-end items-center">
                  {/* Same fixed height */}
                  <Authorization allowedRoles={['Admin']}>
                    <Button
                      className="ml-auto hidden h-8 lg:flex rounded-lg"
                      onClick={() => {
                        setIsEditing(true);
                      }}
                      size="sm"
                      variant="outline"
                      type="button"
                    >
                      Edit
                      <Pen className="ml-2 h-4 w-4" />
                    </Button>
                  </Authorization>
                </div>
              )}
            </div>

            <div className="items-center w-full h-16 text-left inline-flex">
              <Label className="block mx-6 text-base font-thin w-20">
                Name
              </Label>
              {isEditing ? (
                <Input
                  {...register('name', { required: 'Name is required' })}
                  className="rounded-lg"
                  placeholder="Product 01"
                  type="text"
                />
              ) : (
                <Label className="block text-base font-thin w-72">
                  {productQuery.data?.product.name}
                </Label>
              )}
            </div>
            {!isEditing && <Separator className="w-full" />}

            <div className="items-center w-full h-14 text-left inline-flex">
              <Label className="block mx-6 text-base font-thin w-20">SKU</Label>
              {isEditing ? (
                <Input
                  {...register('sku', { required: 'SKU is required' })}
                  className="rounded-lg"
                  placeholder="REM-WX-FLK-S23"
                  type="text"
                />
              ) : (
                <Label className="block text-base font-thin w-72">
                  {productQuery.data?.product.sku}
                </Label>
              )}
            </div>
            {!isEditing && <Separator className="w-full" />}
          </CardContent>
        </Card>
      </form>
    </div>
  );
}
