import { useCallback, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { BatchForm } from '@/components/forms/batch-form';
import type { BatchFormData } from '@/components/forms/batch-form';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { useAppSelector } from '@/hooks/use-app-selector';
import { useBatchesNestedQuery } from '@/hooks/use-batches-nested-query';
import { useCreateBatchMutation } from '@/hooks/use-create-batch-mutation';
import { useProductQuery } from '@/hooks/use-product-query';
import { selectDialog } from '@/store/dialog/dialog.slice';
import { selectProduct } from '@/store/products/products.slice';

import { columns } from './components/data-table/batch-columns';
import { DataTable } from './components/data-table/batch-table';

import type {
  ColumnFiltersState,
  PaginationState,
} from '@tanstack/react-table';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ProductInformation } from './components/product-information/product-information';

export function ProductDetails() {
  const { authAppTemplates } = useFlags();
  const { productId } = useParams() as { productId: string };
  const brand = useAppSelector((state) => state.brands.selected);
  const productQuery = useProductQuery({ productId, brandId: brand?.id ?? '' });

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const batchesQuery = useBatchesNestedQuery({
    productId,
    pagination: {
      take: pagination.pageSize,
      skip: pagination.pageIndex * pagination.pageSize,
    },
    filters: columnFilters,
  });

  const dispatch = useAppDispatch();
  const selectedDialog = useAppSelector((state) => state.dialog.selected);
  const createBatchMutation = useCreateBatchMutation();

  const createBatchHandler = useCallback(
    (batch: BatchFormData) => {
      createBatchMutation.mutate(batch, {
        onSuccess: () => {
          dispatch(selectDialog());
        },
      });
    },
    [createBatchMutation, dispatch],
  );

  function createBatchDialogHandler() {
    dispatch(selectDialog('create-batch'));
  }

  const closeDialogHandler = useCallback(() => {
    dispatch(selectDialog());
    dispatch(selectProduct());
  }, [dispatch]);

  const productDialogData = useMemo(
    () =>
      ({
        'create-batch': {
          header: 'Create Manual Batch',
          description:
            'Add manually a new batch assigned to your product items',
          component: <BatchForm onCreateBatch={createBatchHandler} />,
        },
      }) as const,
    [createBatchHandler],
  );

  const rowCount = batchesQuery.data?.pagination?.total ?? 0;
  if (authAppTemplates) {
    return <ProductInformation />;
  }
  return (
    <>
      <div className="flex-1 space-y-4 p-8 pt-6">
        <div className="flex items-center justify-between space-y-2">
          <div className="flex gap-1">
            <Link className="text-primary hover:underline" to="../products">
              <h2 className="text-2xl font-medium tracking-tight">Products</h2>
            </Link>
            <h2 className="text-2xl font-medium tracking-tight">/</h2>
            <h2 className="text-2xl font-medium tracking-tight">
              {productQuery.data?.product.sku}
            </h2>
            <h2 className="text-2xl font-medium tracking-tight">/</h2>
            <h2 className="text-2xl font-bold tracking-tight">Batches</h2>
          </div>
          <div className="flex items-center space-x-2">
            <Button onClick={createBatchDialogHandler}>
              Create Manual Batch
            </Button>
          </div>
        </div>

        <DataTable
          columnFilters={columnFilters}
          columns={columns}
          data={batchesQuery.data?.batches ?? []}
          isLoading={batchesQuery.isLoading}
          onColumnFiltersChange={setColumnFilters}
          onPaginationChange={setPagination}
          pagination={pagination}
          rowCount={rowCount}
        />
      </div>
      <Dialog
        onOpenChange={closeDialogHandler}
        open={selectedDialog !== undefined}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              {selectedDialog
                ? productDialogData[selectedDialog as 'create-batch'].header
                : null}
            </DialogTitle>
            <DialogDescription>
              {selectedDialog
                ? productDialogData[selectedDialog as 'create-batch']
                    .description
                : null}
            </DialogDescription>
          </DialogHeader>
          {selectedDialog
            ? productDialogData[selectedDialog as 'create-batch'].component
            : null}
        </DialogContent>
      </Dialog>
    </>
  );
}
