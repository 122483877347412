import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { ShortenedUUID } from '@/components/shortened-uuid';
import { Badge } from '@/components/ui/badge';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { getMetafields } from '@/helpers/get-metafields';
import { useScanQuery } from '@/hooks/use-scan-query';
import { useSearchFilters } from '@/hooks/use-search-filters';
import { useTidQuery } from '@/hooks/use-tid-query';
import { scanColumns } from '@/modules/scans/components/data-table/scan-columns';
import { DataTable } from '@/modules/scans/components/data-table/scan-table';

import type {
  ColumnFiltersState,
  PaginationState,
} from '@tanstack/react-table';
import Map from '@/components/dpp-map';
import { JourneySteps } from '@/components/journey-steps';

export const wineTid = 'a01b7d8e-eb18-4533-b7e6-05ed3d0580f2';

type Location = { name: string; coordinates: [number, number]}

const defaultLocations: Location[] = [
  { name: "Production Facility", coordinates: [-117.9, 33.9] },
  { name: "Distribution Center", coordinates: [-84.4, 33.7] },
  { name: "Departed Facility in New York", coordinates: [-74.0, 40.7] },
  { name: "Customer Received", coordinates: [2.29, 48.83] },
]

const wineryLocations: Location[] = [
  { name: "Production Facility", coordinates: [19.121242, -33.912354] },
  { name: "Distribution Center", coordinates: [8.465812, 47.442871] },
  { name: "Reseller: Global Fine Wines and Champagne", coordinates: [5.370614, 43.330448] },
]

export function Tid() {
  const { digitalProductPassport } = useFlags();
  const { tidId } = useParams() as { tidId: string };
  const filters = useSearchFilters();
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const tidQuery = useTidQuery(tidId);

  const scanQuery = useScanQuery({
    filters: columnFilters,
    tidId,
    pagination: {
      take: digitalProductPassport ? 100 : pagination.pageSize,
      skip: pagination.pageIndex * pagination.pageSize,
    },
  });

  useEffect(() => {
    setColumnFilters(filters);
  }, [filters]);

  const metafields = getMetafields(tidQuery.data?.tid.batch.batchMetafields);

  const nfcScanLocations = scanQuery.data?.scans
    .filter(scan => scan.status === 'authenticated' && scan.lat && scan.lon)
    .map( scan => ({
      id: scan._id,
      name: `${scan.city}, ${scan.countryName}`,
      coordinates: [scan.lon, scan.lat] as [number, number]
    }));

    const locations = wineTid === tidId ? wineryLocations : defaultLocations;

  if (tidQuery.isLoading || scanQuery.isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="grid gap-4 lg:grid-cols-6">
      <Card className="flex flex-col gap-4 h-[670px] col-span-2">
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-6 bg-muted">
          <CardTitle className="text-base font-medium w-full flex gap-4 justify-between items-center">
            <div className="grow shrink basis-0 justify-start items-start gap-4 inline-flex">
                {/* 
              <div className='flex justify-between w-full'>
                
                <div className="flex items-center gap-1 text-muted-foreground">
                  <span className="text-muted-foreground">Total Scans</span>
                  <NfcIcon /> 
                  {scanQuery.data?.pagination?.total}
                </div> 
              </div>
                */}
              <div className="bg-white rounded-lg shadow-sm">
                <img src={tidQuery.data?.tid.batch.product.imageUrl ?? ''} alt={tidQuery.data?.tid.batch.product.name} className="w-20 h-20 object-cover rounded-lg" />
              </div>
              <div className='grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex'>
                <div className="self-stretch text-zinc-950 text-lg font-semibold capitalize leading-7 flex items-center justify-between">
                  {tidQuery.data?.tid.batch.product.name}
                </div>
                <div className="self-stretch justify-start items-start gap-1 inline-flex">
                  <div className="text-zinc-500 text-sm font-normal leading-tight">Created at:</div>
                  <div className="text-zinc-500 text-sm font-normal leading-tight">{tidQuery.data?.tid.createdAt}</div>
                </div>
                <div className="self-stretch flex justify-between">
                  <div className="self-stretch justify-start items-start gap-1 inline-flex">
                    <div className="text-zinc-500 text-sm font-normal leading-tight">SKU:</div>
                    <div className="text-zinc-500 text-sm font-normal leading-tight">{tidQuery.data?.tid.batch.product.sku}</div>
                  </div>
                  <Badge variant="success">{tidQuery.data?.tid.isActive ? 'Active' : 'Inactive'}</Badge>
                </div>
              </div>
            </div>
          </CardTitle>
        </CardHeader>
        <CardContent className="w-full flex flex-col gap-4">
          <p className="w-full flex gap-4 justify-between">
            <span className="text-muted-foreground">Batch Id:</span>
            <ShortenedUUID
              copyHidden
              uuid={tidQuery.data?.tid.batchId ?? ''}
            />
          </p>
          <p className="w-full flex gap-4 justify-between">
            <span className="text-muted-foreground">Lot:</span>
            {tidQuery.data?.tid.batch.lot ?? ''}
          </p>
          {tidQuery.data?.tid.batch.producedAt ? (
            <p className="w-full flex gap-4 justify-between">
              <span className="text-muted-foreground">Produced at:</span>
              {tidQuery.data.tid.batch.producedAt}
            </p>
          ) : null}
          {tidQuery.data?.tid.batch.expiresAt ? (
            <p className="w-full flex gap-4 justify-between">
              <span className="text-muted-foreground">Expires at:</span>
              {tidQuery.data.tid.batch.expiresAt}
            </p>
          ) : null}
          {metafields.map((field) => (
            <p className="w-full flex gap-4 justify-between" key={field.key}>
              <span className="text-muted-foreground">{field.key}:</span>
              {field.value}
            </p>
          ))}
          {tidQuery.data?.tid.batch.tags?.length ? (
            <div className="flex flex-col gap-2">
              <p className="w-full flex gap-4 justify-between">
                <span className="text-muted-foreground">Tags:</span>
              </p>
              <div className="flex flex-wrap gap-1">
                {tidQuery.data.tid.batch.tags.map((tag) => (
                  <Badge key={tag} variant="secondary">
                    {tag}
                  </Badge>
                ))}
              </div>
            </div>
          ) : null}
        </CardContent>
      </Card>

      {digitalProductPassport && (
        <Card className="flex gap-4 overflow-hidden col-span-4 h-[670px]">
          <JourneySteps tidId={tidQuery.data?.tid.id} steps={nfcScanLocations} />
          <Map journeyLocations={[
            ...locations ?? [],
            ...(nfcScanLocations ?? [])
          ]} />
        </Card>
      )}
      {!digitalProductPassport && (
        <DataTable
          className={digitalProductPassport ? 'col-span-6' : 'col-span-4'}
          columnFilters={columnFilters}
          columns={scanColumns}
          data={scanQuery.data?.scans ?? []}
          initialColumnVisibility={{
            tidId: false,
            productName: false,
            view: false,
          }}
          onColumnFiltersChange={setColumnFilters}
          onPaginationChange={setPagination}
          pagination={pagination}
          rowCount={scanQuery.data?.pagination?.total ?? 0}
          showToolbar={false}
        />
      )}
    </div>
  );
}
