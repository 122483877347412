import { useCallback, useMemo, useState } from 'react';

import { FileUploadForm } from '@/components/forms/image-upload-form';
import type { CreateProductFormData } from '@/components/forms/product-form';
import { ProductForm } from '@/components/forms/product-form';

import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';

import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { useAppSelector } from '@/hooks/use-app-selector';
import { useCreateProductMutation } from '@/hooks/use-create-product-mutation';
import { useProductQuery } from '@/hooks/use-product-query';
import { useProductsQuery } from '@/hooks/use-products-query';
import { useSearchFilters } from '@/hooks/use-search-filters';
import { useUpdateProductImageMutation } from '@/hooks/use-update-product-image-mutation';
// import { useUpdateProductMutation } from '@/hooks/use-update-product-mutation';

import { selectDialog } from '@/store/dialog/dialog.slice';
import { selectProduct } from '@/store/products/products.slice';

// Data Table Components and Columns
import { columns } from './components/data-table/product-columns';
import { DataTable } from './components/data-table/product-table';

import type {
  ColumnFiltersState,
  PaginationState,
} from '@tanstack/react-table';

export function Products() {
  const selectedProduct = useAppSelector((state) => state.products.selected);
  const filters = useSearchFilters();
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const productsQuery = useProductsQuery({
    pagination: {
      take: pagination.pageSize,
      skip: pagination.pageIndex * pagination.pageSize,
    },
    filters,
  });
  const brand = useAppSelector((state) => state.brands.selected);
  const productQuery = useProductQuery({
    productId: selectedProduct?.id || '',
    brandId: brand?.id || '',
  });
  const dispatch = useAppDispatch();
  const selectedDialog = useAppSelector((state) => state.dialog.selected);
  const createProductMutation = useCreateProductMutation();
  // const updateProductMutation = useUpdateProductMutation();
  const updateProductImageMutation = useUpdateProductImageMutation();

  const createProductHandler = useCallback(
    async (product: CreateProductFormData) => {
      await createProductMutation.mutateAsync(product);
      dispatch(selectDialog());
      dispatch(selectProduct());
    },
    [createProductMutation, dispatch],
  );

  // const updateProductHandler = useCallback(
  //   async (product: CreateProductFormData) => {
  //     await updateProductMutation.mutateAsync({
  //       ...product,
  //       productId: selectedProduct?.id,
  //     });
  //     dispatch(selectDialog());
  //     dispatch(selectProduct());
  //   },
  //   [updateProductMutation, selectedProduct?.id, dispatch],
  // );

  function productInfoDialogHandler() {
    dispatch(selectDialog('create-product'));
  }

  const closeDialogHandler = useCallback(() => {
    dispatch(selectDialog());
    dispatch(selectProduct());
  }, [dispatch]);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const formData = new FormData();

      formData.append('image', acceptedFiles[0]);

      await updateProductImageMutation.mutateAsync(formData);
      dispatch(selectDialog());
      dispatch(selectProduct());
    },
    [dispatch, updateProductImageMutation],
  );

  const productDialogData = useMemo(
    () =>
      ({
        'create-product': {
          header: 'Create Product',
          description: 'Add a new product to manage batches and tags.',
          component: (
            <ProductForm
              onCancel={closeDialogHandler}
              onSubmit={createProductHandler}
            />
          ),
        },
        'upload-image': {
          header: 'Upload Product Image',
          description: 'Add/update an image for product authentication',
          component: (
            <FileUploadForm
              filename={productQuery.data?.product.image ?? undefined}
              onDrop={onDrop}
              url={productQuery.data?.product.imageUrl ?? undefined}
            />
          ),
        },
      }) as const,
    [
      closeDialogHandler,
      createProductHandler,
      onDrop,
      productQuery.data?.product,
    ],
  );

  return (
    <>
      <div className="flex-1 space-y-4 p-8 pt-6">
        <div className="flex items-center justify-between space-y-2">
          <div className="flex gap-1">
            <h2 className="text-2xl font-bold tracking-tight">Products</h2>
          </div>
          <div className="flex items-center space-x-2">
            <Button onClick={productInfoDialogHandler}>
              Create new product
            </Button>
          </div>
        </div>
        <DataTable
          columnFilters={columnFilters}
          columns={columns}
          data={productsQuery.data?.products ?? []}
          isLoading={productsQuery.isLoading}
          onColumnFiltersChange={setColumnFilters}
          onPaginationChange={setPagination}
          pagination={pagination}
        />
      </div>
      <Dialog
        onOpenChange={closeDialogHandler}
        open={selectedDialog !== undefined}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              {selectedDialog
                ? productDialogData[
                    selectedDialog as 'create-product' | 'upload-image'
                  ].header
                : null}
            </DialogTitle>
            <DialogDescription>
              {selectedDialog
                ? productDialogData[
                    selectedDialog as 'create-product' | 'upload-image'
                  ].description
                : null}
            </DialogDescription>
          </DialogHeader>
          {selectedDialog
            ? productDialogData[
                selectedDialog as 'create-product' | 'upload-image'
              ].component
            : null}
        </DialogContent>
      </Dialog>
    </>
  );
}
