import { z } from 'zod';

import { formatDate } from '@/helpers/format-date';

export const scanSchema = z.object({
  _id: z.string(),
  status: z.string(),
  tidId: z.string().nullable().optional(),
  tagTamper: z.boolean().nullable().optional(),
  productName: z.string().nullable().optional(),
  sku: z.string().nullable().optional(),
  countryCode: z.string().nullable().optional(),
  countryName: z.string().nullable().optional(),
  regionCode: z.string().nullable().optional(),
  regionName: z.string().nullable().optional(),
  city: z.string().nullable().optional(),
  lat: z.number().nullable().optional(),
  lon: z.number().nullable().optional(),
  createdAt: z.string().datetime().transform(formatDate),
});

export const scanCountsSchema = z.object({
  total: z.number(),
  authenticated: z.number(),
  counterfeit: z.number(),
  invalid: z.number(),
  rescan: z.number(),
});

export type Scan = z.infer<typeof scanSchema>;
export type ScanCounts = z.infer<typeof scanCountsSchema>;
